<script>
    import TransitionContainer from "@/components/TransitionContainer";
    import { settings } from "@/ThePersonal/settings";
    import ThePersonalIcon from "@/ThePersonal/components/ThePersonalIcon";

    export default {
        name: "ThePersonalSupportFaq",
        components: {
            ThePersonalIcon,
            TransitionContainer,
        },
        props: {
            faqLink: String,
        },
        data() {
            return {
                indexes: [1],
            };
        },
        computed: {
            links() {
                return settings[this.$root.$_name].links;
            },
            faqText() {
                return [
                    {
                        id: 1,
                        title: "Как отслеживать доставку заказа?",
                        text: `<p>Проверить статус заказа и узнать его трек-номер можно по <a href="${this.links.status}" target="_blank">ссылке</a>, а также в Личном кабинете на сайте.</p>`,
                    },
                    {
                        id: 2,
                        title: "Доставка задерживается, что делать?",
                        text: `<p>Пожалуйста, свяжитесь с нами удобным для вас способом: по <a href="${this.links.whatsapp}" target="_blank">WhatsApp</a>, <a href="${this.links.telegram}" target="_blank">Telegram</a> или по почте <a href="mailto:${this.links.info}" target="_blank">${this.links.info}</a>, указав номер заказа. Мы постараемся решить вопрос как можно быстрее.</p>`,
                    },
                    {
                        id: 3,
                        title: "Как вернуть товар, если он не подошел?",
                        text: `<p>Если товар не подошел, вы вправе вернуть его в течение 14 дней после получения и в том случае, если он не потерял потребительские свойства. Возврат возможен одним из способов: </p><ol><li>В один из наших розничных магазинов и ПВЗ.</li><li>Почтой России по адресу 119270, г.Москва, а/я 8, ООО «Мэдроботс Стор».</li></ol><p>Подробнее о процедуре — в разделе <a href="${this.links.warranty}" target="_blank">Гарантия и возврат</a>. Дополнительные вопросы можно уточнить в сервисном центре Madrobots по почте <a href="mailto:${this.links.service}">service@madrobots.ru</a></p>`,
                    },
                    {
                        id: 4,
                        title: "Привезли не тот товар, что делать?",
                        text: `<p>Пожалуйста, отправьте нам фото товара и номер своего заказа в <a href="${this.links.whatsapp}" target="_blank">WhatsApp</a>, <a href="${this.links.telegram}" target="_blank">Telegram</a> или на почту <a href="mailto:${this.links.info}" target="_blank">${this.links.info}</a>. Мы согласуем с вами быстрый обмен или, при желании, возврат товара.</p>`,
                    },
                    {
                        id: 5,
                        title: "Вы работаете с юр. лицами?",
                        text: `<p>Да. Возможно выставление счета и заключение договора. Работаем по упрощенной системе налогообложения, без НДС. Для оформления заказа свяжитесь с нами по почте <a href="mailto:${this.links.invoice}" target="_blank">${this.links.invoice}</a>.</p>`,
                    },
                    {
                        id: 6,
                        title: "Как получить скидку?",
                        text: "<p>Если вы нашли товар дешевле, мы готовы снизить цену. Пожалуйста, обратитесь в нашу службу поддержки и пришлите ссылку, подтверждающую предложение. Менеджеры проведут сравнение и предоставят вам ответ.</p>",
                    },
                    {
                        id: 7,
                        title: "Что означает «Предзаказ?»",
                        text: "<p>Если вас заинтересовал товар, которого пока нет в наличии, вы можете оставить заявку. Мы в числе первых уведомим вас, как только товар поступит в продажу.</p>",
                    },
                    {
                        id: 8,
                        title: "Не получается оформить заказ (нет доставки нужный регион)",
                        text: "<p>Если у вас возникли проблемы с оформлением заказа, пожалуйста, обратитесь в службу поддержки удобным для вас способом. Список всех каналов связи — в правом нижнем углу сайта. Или позвоните по номеру <a href='tel:88007754968' target='_blank'>8 800 7754968</a>. Менеджеры обязательно вам помогут.</p>",
                    },
                    {
                        id: 9,
                        title: "В каких интернет-магазинах можно получить кешбэк при покупке?",
                        text: "<p>Бонусная программа действует в интернет-магазинах <a href='https://madrobots.ru/' target='_blank'>madrobots.ru</a>, <a href='https://xd-design.ru/' target='_blank'>xd-design.ru</a>, <a href='https://picooc.ru/' target='_blank'>picooc.ru</a>, <a href='https://miku-russia.ru/' target='_blank'>miku-russia.ru</a>, <a href='https://petoneer.ru/' target='_blank'>petoneer.ru</a>, <a href='https://chipolo.ru/' target='_blank'>chipolo.ru</a>, <a href='https://bivan.ru/' target='_blank'>bivan.ru</a>, <a href='https://ledgerwalletrussia.ru/' target='_blank'>ledgerwalletrussia.ru</a>, <a href='https://zavtra.co/' target='_blank'>zavtra.co</a>.</p>",
                    },
                    {
                        id: 10,
                        title: "Как зайти в Личный кабинет на других сайтах проекта?",
                        text: "<p>Для входа в Личный кабинет на любом из сайтов: <a href='https://madrobots.ru/' target='_blank'>madrobots.ru</a>, <a href='https://xd-design.ru/' target='_blank'>xd-design.ru</a>, <a href='https://picooc.ru/' target='_blank'>picooc.ru</a>, <a href='https://miku-russia.ru/' target='_blank'>miku-russia.ru</a>, <a href='https://petoneer.ru/' target='_blank'>petoneer.ru</a>, <a href='https://chipolo.ru/' target='_blank'>chipolo.ru</a>, <a href='https://bivan.ru/' target='_blank'>bivan.ru</a>, <a href='https://ledgerwalletrussia.ru/' target='_blank'>ledgerwalletrussia.ru</a>, <a href='https://zavtra.co/' target='_blank'>zavtra.co</a> — используйте один и тот же логин и пароль. Данные для входа были высланы вам в письме при регистрации.</p>",
                    },
                ];
            },
        },
        methods: {
            toggleAccordion(id) {
                if (this.indexes.includes(id)) {
                    const removedIndex = this.indexes.indexOf(id);
                    this.indexes.splice(removedIndex, 1);
                } else {
                    this.indexes.push(id);
                }
            },
            scrollTop() {
                window.scrollTo({ top: 0, behavior: "smooth" });
            },
            goToFaqPage() {
                if (this.faqLink.startsWith("/")) {
                    window.location.href = this.faqLink;
                } else {
                    this.$router.push({ name: this.faqLink });
                }
            },
        },
    };
</script>

<template>
    <div class="the-personal-support-faq">
        <div
            class="the-personal-support-faq__arrow"
            v-show="faqLink && $root.$_nameDev !== 'xd-design'"
        >
            <the-personal-icon name="arrow"></the-personal-icon>
        </div>
        <div class="the-personal-support-faq__header">
            <div class="the-personal-support-faq__icon">
                <the-personal-icon name="faq"></the-personal-icon>
            </div>
            <div class="the-personal-support-faq__title">Частые вопросы</div>
            <router-link
                v-show="faqLink && $root.$_nameDev !== 'xd-design'"
                to=""
                class="the-personal-support-faq__more mobile-hidden"
                @click.native="goToFaqPage"
            >
                Смотреть все вопросы
            </router-link>
            <router-link
                v-show="faqLink"
                to=""
                class="the-personal-support-faq__more desktop-hidden"
                @click.native="goToFaqPage"
                >Все вопросы
            </router-link>
        </div>
        <div class="the-personal-support-faq__content">
            <div class="the-personal-support-faq__item" v-for="text in faqText" :key="text.id">
                <button
                    class="the-personal-support-faq__trigger outline"
                    @click="toggleAccordion(text.id)"
                >
                    {{ text.title }}
                    <span
                        class="the-personal-support-faq__trigger-icon"
                        :class="{ opened: indexes.includes(text.id) }"
                    >
                    </span>
                </button>

                <transition-container :show="indexes.includes(text.id)">
                    <div class="the-personal-support-faq__text" v-html="text.text"></div>
                </transition-container>
            </div>
        </div>
        <div class="the-personal-support-faq__scroll-stop"></div>
        <button class="the-personal-support-faq__scroll-button outline" @click="scrollTop">
            <the-personal-icon name="arrow"></the-personal-icon>
        </button>
    </div>
</template>

<style lang="scss">
    .the-personal-support-faq {
        position: relative;
        padding: 10px 15px 5px;
        background-color: #fff;
        border-radius: $personal-box-radius;
        box-shadow: $personal-common-shadow-min;
        border: $personal-common-border-full;
        @include bp($bp-desktop-sm) {
            min-height: 135px;
            padding: 20px 20px 10px;
            box-shadow: $personal-common-shadow-max;
            outline: none;
        }
    }

    .the-personal-support-faq__scroll-stop {
        display: none;
        @include bp($bp-desktop-sm) {
            position: absolute;
            bottom: 60px;
            right: -50px;
            display: block;
        }
    }

    .the-personal-support-faq__scroll-button {
        display: none;
        @include bp($bp-desktop-sm) {
            position: absolute;
            bottom: 15px;
            right: -50px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            background-color: $personal-button-fill;
            border-radius: 50%;
            transition: background-color 0.2s ease-in;
            &:hover {
                background-color: $personal-button-fill--hover;
            }
            svg.icon-arrow {
                fill: #fff;
                width: 12px;
                height: 12px;
                transform: rotate(-90deg);
            }
        }
    }

    .the-personal-support-faq__header {
        display: flex;
        align-items: center;
        padding-right: 12px;
        @include bp($bp-desktop-sm) {
            padding-right: 22px;
        }
    }

    .the-personal-support-faq__arrow {
        position: absolute;
        top: 13px;
        right: 15px;
        width: 12px;
        height: 12px;
        svg.icon-arrow {
            width: 12px;
            height: 12px;
            fill: $personal-active-color;
            vertical-align: top;
        }
        @include bp($bp-desktop-sm) {
            top: 26px;
            right: 30px;
        }
    }

    .the-personal-support-faq__icon {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        svg {
            width: 16px;
            height: 16px;
            fill: $personal-card-title-color;
        }
        @include bp($bp-desktop-sm) {
            width: 24px;
            height: 24px;
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }

    .the-personal-support-faq__title {
        color: $personal-card-title-color;
        font-size: 14px;
        font-weight: $personal-card-title-weight;
        line-height: 16px;
        transition: color 0.2s ease-in;
        @include bp($bp-desktop-sm) {
            font-size: 18px;
        }
    }

    .the-personal-support-faq__more {
        margin-left: auto;
        color: $personal-active-color;
        font-size: 14px;
        line-height: 16px;
        @include bp($bp-desktop-sm) {
            font-size: 16px;
            line-height: 18px;
        }
    }

    .the-personal-support-faq__item {
        padding-bottom: 15px;
        &:not(:last-child) {
            border-color: $personal-divider-fill;
            border-width: 0 0 1px 0;
            border-style: solid;
            border-image: var(--border-image-gradient);
            border-image-slice: 1;
        }
        @include bp($bp-desktop-sm) {
            padding-bottom: 20px;
        }
    }

    .the-personal-support-faq__trigger {
        position: relative;
        width: 100%;
        margin-top: 15px;
        padding-right: 25px;
        color: $personal-card-title-color;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        text-align: left;
        @include bp($bp-desktop-sm) {
            margin-top: 20px;
            padding-right: 35px;
            font-size: 16px;
        }
    }

    .the-personal-support-faq__trigger-icon {
        position: absolute;
        right: 0;
        top: 50%;
        width: 26px;
        height: 26px;
        border: 2px solid transparent;
        border-radius: 50%;
        transform: translateY(-50%);
        transition: border-color 0.2s ease-in;
        &.opened {
            border-color: $personal-card-border-color;
            &:before {
                transform: rotate(90deg);
                opacity: 0;
            }
            &:after {
                transform: rotate(0);
            }
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            right: 5px;
            top: calc(50% - 1px);
            width: 12px;
            height: 2px;
            background-color: $personal-card-border-color;
            border-radius: 5px;
            transition: transform 0.2s ease-in, opacity 0.2s ease-in;
        }
        &:after {
            transform: rotate(-90deg) translateZ(0);
        }
        @include bp($bp-desktop-sm) {
            right: 10px;
        }
    }

    .the-personal-support-faq__text {
        padding-top: 15px;
        color: $personal-card-text-color;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        @include bp($bp-desktop-sm) {
            padding-top: 20px;
            font-size: 16px;
            line-height: 28px;
        }
        a {
            color: #4285f4;
        }
        p {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
        ol {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
            padding-left: 20px;
            list-style: decimal;
        }
    }
</style>
