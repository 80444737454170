<script>
    import { settings } from "@/ThePersonal/settings";
    import ThePersonalSupportCard from "@/ThePersonal/ThePersonalSupportCard";
    import ThePersonalSupportFaq from "@/ThePersonal/ThePersonalSupportFaq";
    import ThePersonalSupportSide from "@/ThePersonal/ThePersonalSupportSide";

    export default {
        name: "ThePersonalSupport",
        components: { ThePersonalSupportSide, ThePersonalSupportFaq, ThePersonalSupportCard },
        computed: {
            routes() {
                return [
                    {
                        title: "Оплата",
                        text: "Здесь вы узнаете все о способах оплаты в нашем магазине.",
                        route: this.getSiteRoute("payment"),
                        routeHash: "#payment",
                        icon: "payment",
                    },
                    {
                        title: "Доставка",
                        text: "В разделе вы найдете ответы на вопросы связанные с доставкой, видами доставки, как получить бесплатную доставку и многое другое.",
                        route: this.getSiteRoute("delivery"),
                        icon: "delivery",
                    },
                    {
                        title: "Гарантия и возврат",
                        text: "Данный раздел расскажет вам о нашей гарантии, ее сроках, а также об условиях возврата покупок.",
                        route: this.getSiteRoute("guarantee"),
                        icon: "guarantee",
                    },
                    {
                        title: "Магазины",
                        text: "Расскажем вам как найти наши офлайн-магазины в Санкт-Петербурге и Москве.",
                        route: this.getSiteRoute("store"),
                        icon: "store",
                    },
                ];
            },
        },
        methods: {
            getSiteRoute(name) {
                return settings[this.$root.$_name].pages[name];
            },
        },
    };
</script>

<template>
    <section class="the-personal-support">
        <div class="the-personal-support__inner">
            <the-personal-support-card
                v-show="link.route"
                v-for="(link, index) in routes"
                :key="index"
                class="the-personal-support__card"
                :link="link"
            ></the-personal-support-card>
        </div>
        <div class="the-personal-support__inner">
            <div class="the-personal-support__faq">
                <the-personal-support-faq
                    :faq-link="getSiteRoute('faq')"
                ></the-personal-support-faq>
            </div>
            <div class="the-personal-support__sidebar js-scroll-aside">
                <the-personal-support-side></the-personal-support-side>
            </div>
        </div>
    </section>
</template>

<style lang="scss">
    .the-personal-support__inner {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        @include bp($bp-desktop-sm) {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;
            &:not(:last-child) {
                margin-bottom: 0;
            }
        }
    }

    .the-personal-support__card {
        margin-bottom: 10px;
        @include bp($bp-desktop-sm) {
            flex-basis: calc(50% - 20px);
            max-width: calc(50% - 20px);
            margin: 0 10px 20px;
        }
    }

    .the-personal-support__faq {
        margin-bottom: 10px;
        @include bp($bp-desktop-sm) {
            max-width: calc(100% - 245px - 40px);
            width: 100%;
            margin: 0 10px;
        }
    }

    .the-personal-support__sidebar {
        @include bp($bp-desktop-sm) {
            display: block;
            width: 100%;
            max-width: 245px;
            margin: 0 10px;
        }
    }
</style>
