<script>
    import { mapState } from "vuex";
    import { isMobile, stickyScroll, throttle } from "@/utils";
    import { imageUrl } from "@/ThePersonal/utils";
    import { settings } from "@/ThePersonal/settings";
    import ThePersonalButton from "@/ThePersonal/components/ui/ThePersonalButton";

    export default {
        name: "ThePersonalSupportSide",
        components: { ThePersonalButton },
        data() {
            return {
                stickyScrollWithThrottle: throttle(this.stickyNavScroll, 35),
                navScrollPosition: 0,
            };
        },
        computed: {
            ...mapState("Root", {
                phone: (state) => state.site.phone,
            }),
            sitePhone() {
                if (this.$root.$_nameDev) {
                    return this.phone;
                } else if (this.$root.$_isMadRobots) {
                    return settings.madrobots.phone;
                } else {
                    return settings.nospa.phone;
                }
            },
            imageUrl() {
                return {
                    desktop: imageUrl(this.$root.$_nameDev, "support-aside.png"),
                    mobile: imageUrl(this.$root.$_nameDev, "support-aside--mobile.png"),
                };
            },
        },
        mounted() {
            window.addEventListener("scroll", this.stickyScrollWithThrottle);
            window.addEventListener("resize", this.windowResizeHandler);
            const siteHeaderHeight = document
                .querySelector(".js-desktop-header")
                .getBoundingClientRect().height;
            this.navScrollPosition = siteHeaderHeight + 30;
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.stickyScrollWithThrottle);
            window.removeEventListener("resize", this.windowResizeHandler);
        },
        methods: {
            stickyNavScroll() {
                if (!isMobile(992)) {
                    const el = document.querySelector(".js-scroll-aside");
                    const elWrap = document.querySelector(".js-scroll-aside-wrapper");
                    if (el && elWrap) {
                        stickyScroll({
                            el,
                            elWrap,
                            topMargin: this.navScrollPosition,
                            classNameDivider: "the-personal-support-faq__scroll-stop",
                        });
                    }
                }
            },
            windowResizeHandler() {
                if (isMobile(992)) {
                    const el = document.querySelector(".js-scroll-aside");
                    const elWrap = document.querySelector(".js-scroll-aside-wrapper");

                    el.style.height = "auto";
                    elWrap.style.top = "auto";
                    elWrap.style.width = "auto";
                }
            },
            callUsedeskChat() {
                if (window.usedeskMessenger) {
                    window.usedeskMessenger.open("chat");
                }
            },
        },
    };
</script>

<template>
    <div class="the-personal-support-side js-scroll-aside-wrapper">
        <div class="the-personal-support-side__image">
            <picture>
                <source media="(max-width: 992px)" :srcset="imageUrl.mobile" />
                <img :src="imageUrl.desktop" alt="" />
            </picture>
        </div>
        <div class="the-personal-support-side__text">
            Если вы не нашли ответа на свой вопрос — обратитесь в службу поддержки по номеру
        </div>
        <a :href="`tel:${sitePhone}`" class="the-personal-support-side__phone">
            {{ sitePhone }}
        </a>
        <the-personal-button @click="callUsedeskChat">Написать в чат</the-personal-button>
    </div>
</template>

<style lang="scss">
    .the-personal-support-side {
        position: relative;
        z-index: 3;
        padding: 15px;
        background-color: #fff;
        border-radius: $personal-box-radius;
        box-shadow: $personal-common-shadow-min;
        border: $personal-common-border-full;
        @include bp($bp-desktop-sm) {
            padding: 110px 20px 20px;
            box-shadow: $personal-common-shadow-max;
            &.fix-scroll-block {
                position: fixed;
            }
        }
    }

    .the-personal-support-side__image {
        position: absolute;
        right: 0;
        top: 0;
        width: 134px;
        height: 103px;
        img {
            width: 100%;
        }
        @include bp($bp-desktop-sm) {
            width: 100%;
            height: 100px;
        }
    }

    .the-personal-support-side__text {
        max-width: 180px;
        margin-bottom: 12px;
        color: #8f90a6;
        font-size: 12px;
        line-height: 16px;
        @include bp($bp-desktop-sm) {
            max-width: 100%;
            margin-bottom: 10px;
            color: #555770;
            font-size: 14px;
            font-weight: 300;
            line-height: 22px;
        }
    }

    .the-personal-support-side__phone {
        display: block;
        margin-bottom: 20px;
        color: $personal-widget-link-color;
        font-size: 16px;
        line-height: 20px;
        text-decoration: underline;
        @include bp($bp-desktop-sm) {
        }
    }
</style>
