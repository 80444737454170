<script>
    import { isMobile } from "@/utils";
    import ThePersonalIcon from "@/ThePersonal/components/ThePersonalIcon";
    import ThePersonalTooltip from "@/ThePersonal/components/ui/ThePersonalTooltip";

    export default {
        name: "ThePersonalSupportCard",
        components: { ThePersonalTooltip, ThePersonalIcon },
        props: {
            link: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                timeout: null,
                isTooltipShow: false,
            };
        },
        methods: {
            mouseEnterHandler() {
                if (!isMobile(992)) {
                    this.timeout = setTimeout(() => {
                        this.isTooltipShow = true;
                    }, 3000);
                }
            },
            mouseLeaveHandler() {
                if (!isMobile(992)) {
                    clearTimeout(this.timeout);
                    this.isTooltipShow = false;
                }
            },
        },
    };
</script>

<template>
    <router-link
        :to="{ name: link.route, hash: link.routeHash }"
        class="the-personal-support-card"
        @mouseenter.native="mouseEnterHandler"
        @mouseleave.native="mouseLeaveHandler"
    >
        <div class="the-personal-support-card__arrow">
            <the-personal-icon name="arrow"></the-personal-icon>
            <div class="the-personal-support-card__tooltip" v-show="isTooltipShow">
                <the-personal-tooltip width="120" position-x="right" position-y="bottom">
                    Перейти в раздел
                </the-personal-tooltip>
            </div>
        </div>
        <div class="the-personal-support-card__header">
            <div class="the-personal-support-card__icon">
                <the-personal-icon :name="link.icon"></the-personal-icon>
            </div>
            <div class="the-personal-support-card__title">
                {{ link.title }}
            </div>
        </div>
        <div class="the-personal-support-card__text">
            {{ link.text }}
        </div>
    </router-link>
</template>

<style lang="scss">
    .the-personal-support-card {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-height: 100px;
        padding: 10px 15px 15px;
        color: $personal-card-title-color;
        background-color: #fff;
        border-radius: $personal-box-radius;
        border: $personal-border-width solid $personal-common-border-color;
        box-shadow: $personal-common-shadow-min;
        transition: border-color 0.2s ease-in, box-shadow 0.2s ease-in;
        @include bp($bp-desktop-sm) {
            min-height: 135px;
            padding: 20px 20px 15px;
            box-shadow: $personal-common-shadow-max;
            outline: none;
            &:hover,
            &:focus-visible {
                color: $personal-card-border-color;
                border-color: $personal-card-border-color;
                box-shadow: none;
                .the-personal-support-card__icon {
                    svg {
                        fill: $personal-card-border-color;
                    }
                }
            }
            &:active {
                color: $personal-card-border-color--active;
                border-color: $personal-card-border-color--active;
                .the-personal-support-card__icon {
                    svg {
                        fill: $personal-card-border-color--active;
                    }
                }
            }
        }
    }

    .the-personal-support-card__header {
        display: flex;
        align-items: center;
    }

    .the-personal-support-card__arrow {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 12px;
        height: 12px;
        svg.icon-arrow {
            width: 12px;
            height: 12px;
            fill: $personal-card-border-color;
            vertical-align: top;
        }
        @include bp($bp-desktop-sm) {
            top: 25px;
            right: 20px;
        }
    }

    .the-personal-support-card__icon {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        svg {
            width: 16px;
            height: 16px;
            fill: $personal-card-title-color;
            transition: fill 0.2s ease-in;
        }
        @include bp($bp-desktop-sm) {
            width: 24px;
            height: 24px;
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }

    .the-personal-support-card__title {
        font-size: 14px;
        font-weight: $personal-card-title-weight;
        line-height: 16px;
        transition: color 0.2s ease-in;
        @include bp($bp-desktop-sm) {
            font-size: 18px;
        }
    }

    .the-personal-support-card__text {
        position: relative;
        margin-top: auto;
        color: $personal-card-text-color;
        font-size: 12px;
        font-weight: $personal-card-text-weight;
        line-height: 16px;
        @include bp($bp-desktop-sm) {
            font-size: 14px;
            line-height: 22px;
        }
    }
</style>
